import request from '@/utils/request'


// 查询接口列表
export function getRecords(param) {
  
    return request({
      url: '/baseinfo/records',
      method: 'get',
      params:param
    })
  }
