<template>
<div>
<BaseInfo :baseInfo="$store.state.user.baseInfo" />
<div>
  <NavBar
  title="体检记录"
  left-arrow
  @click-left="$router.go(-1)"
/>
  <steps  direction="vertical" :active="active" active-icon="success" active-color="#38f">
  <step v-for="(record,index) in records" :key="index">
    <h3><a @click="viewtjdata(record.id)" >{{record.enterDate}}</a></h3>
  </step>
</steps>
<empty v-if="nodata" description="查无数据" />
</div>
<RLFooter/>
</div>
</template>

<script>

import {  Step,Steps,Empty } from 'vant';
import {getRecords} from '@/api/tjdata/record';

export default {
  name: 'Tjdata',
  components: {
      Step,Steps,Empty
  },
  data(){
    return {
      records:[],
      active:0,
      nodata:false
    };
  },
  methods:{
      viewtjdata(rid){
        console.info(this.$store.state.user.openid);
        console.info('查看体检数据-->['+rid+']');
        this.$router.push({path:"/tjdata",query:{rid:rid}});
      }
      
  }
,
   created() {
    /*初始化section*/
    console.info(this.$store.state.user)
    getRecords({openid:this.$store.state.user.openid}).then((response) => {
          this.records=response.data;
          this.active=response.data.length;
          if(this.records.length==0){
              this.nodata=true;
          }
      });
  },
}
</script>

<style scoped>
.custom-title {
    margin-right: 4px;
    vertical-align: middle;
  }

  .search-icon {
    font-size: 16px;
    line-height: inherit;
  }

  .custom-value{
    
     margin-left: 0em;
  }
  .custom-unit{
      margin-left: .5em;
  }
</style>
